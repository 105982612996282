import React, { Component } from 'react';
import './../styles/Menu.css';
import Navigation from './Navigation';
import Settings from './Settings';
import Spin from './Spin';
import Story from './Story';
import navigationImg from './../assets/navigation-512x512.png';
import settingsImg from './../assets/settings-512x512.png';
import { Text, menu, navigation, settings } from './../util/util';

type State = {
  menuCollection: boolean[];
  viewCollection: JSX.Element[];
  viewIndex: number;
}

class Menu extends Component<{}, State> {

  state: State = {
    menuCollection: [false, false],
    viewCollection: [<Spin />, <Story />],
    viewIndex: 0,
  }

  changeView = (view: string) => {
    const viewIndex = this.getViewIndex(view);
    this.setState(() => ({
      viewIndex: viewIndex,
    }));
  }

  toggleMenu = (menu?: string) => {
    if (menu) {
      const menuIndex = this.getMenuIndex(menu);
      const menuCollection: boolean[] = [];
      for (let i = 0; i < this.state.menuCollection.length; i++) {
        i === menuIndex
          ? menuCollection.push(!this.state.menuCollection[menuIndex])
          : menuCollection.push(false);
      }
      this.setState(() => ({
        menuCollection: menuCollection,
      }));
    }
    else if (!menu) {
      const menuCollection: boolean[] = [];
      for (let i = 0; i < this.state.menuCollection.length; i++) {
        menuCollection.push(false);
      }
      this.setState(() => ({
        menuCollection: menuCollection,
      }));
    }
  }

  getMenuIndex = (view: string) => {
    switch (view) {
      case menu[0]:
        return 0;
      case menu[1]:
        return 1;
      default:
        return 0;
    }
  }

  getViewIndex = (view: string) => {
    switch (view) {
      case navigation[0]:
        return 0;
      case navigation[1]:
        return 1;
      default:
        return 0;
    }
  }

  render() {
    return(
      <div>
        <div
          className='view'
          onClick={() => this.toggleMenu()}
        >
          {this.state.viewCollection[this.state.viewIndex]}
        </div>
        <div className='menu'>
          <Navigation
            navigationImgSrc={navigationImg}
            navigationImgAlt={Text.Navigation}
            navigationIsActive={this.state.menuCollection[0]}
            navigationMenuItems={navigation}
            changeView={this.changeView}
            toggleMenu={this.toggleMenu}
          />
          <Settings
            settingsImgSrc={settingsImg}
            settingsImgAlt={Text.Settings}
            settingsIsActive={this.state.menuCollection[1]}
            settingsMenuItems={settings}
            changeView={this.changeView}
            toggleMenu={this.toggleMenu}
          />
        </div>
      </div>
    );
  }
}

export default Menu;
