import React, { Component } from 'react';
import './../styles/Navigation.css';
import { Link, Text } from './../util/util';

type Props = {
  navigationImgSrc: string;
  navigationImgAlt: string;
  navigationIsActive: boolean;
  navigationMenuItems: string[];
  changeView: (view: string) => void;
  toggleMenu: (menu?: string) => void;
}

class Navigation extends Component<Props> {

  changeView = (view: string) => {
    this.toggleMenu();
    this.props.changeView(view);
  }

  toggleMenu = () => {
    this.props.toggleMenu(Text.Navigation);
  }

  render() {
    return(
      <div className='navigation'>
        {this.props.navigationIsActive
          ? <div className='menu-list'>
              {this.props.navigationMenuItems.map(i => (
                <p
                  key={i}
                  className='menu-list-item'
                  onClick={() => this.changeView(i)}
                >
                  {i}
                </p>
              ))}
              <p className='menu-list-item'>
                <a href={Link.Subscribe}>{Text.Subscribe}</a>
              </p>
            </div>
          : null
        }
        <div
          className={this.props.navigationIsActive
            ? 'menu-button menu-is-active'
            : 'menu-button menu-is-inactive'
          }
          onClick={this.toggleMenu}
        >
          <img
            className='menu-button-img'
            src={this.props.navigationImgSrc}
            alt={this.props.navigationImgAlt}
          />
        </div>
      </div>
    );
  }
}

export default Navigation;
