import React, { Component } from 'react';
import './../styles/Settings.css';
import facebookImg from './../assets/facebook-512x512.png';
import twitterImg from './../assets/twitter-512x512.png';
import instagramImg from './../assets/instagram-512x512.png';
import linkedinImg from './../assets/linkedin-512x512.png';
import { Link, Text } from './../util/util';

type Props = {
  settingsImgSrc: string;
  settingsImgAlt: string;
  settingsIsActive: boolean;
  settingsMenuItems: string[];
  changeView: (view: string) => void;
  toggleMenu: (menu?: string) => void;
}

class Settings extends Component<Props> {

  changeView = (view: string) => {
    this.toggleMenu();
    this.props.changeView(view);
  }

  toggleMenu = () => {
    this.props.toggleMenu(Text.Settings);
  }

  render() {
    return(
      <div className='settings'>
        {this.props.settingsIsActive
          ? <div className='menu-list'>
              <div className='settings-menu'>
                <p>
                  <a href={Link.Facebook}>
                    <img
                      className='settings-menu-img'
                      src={facebookImg}
                      alt={Text.Facebook}
                    />
                  </a>
                </p>
                <p>
                  <a href={Link.Twitter}>
                    <img
                      className='settings-menu-img'
                      src={twitterImg}
                      alt={Text.Twitter}
                    />
                  </a>
                </p>
                <p>
                  <a href={Link.Instagram}>
                    <img
                      className='settings-menu-img'
                      src={instagramImg}
                      alt={Text.Instagram}
                    />
                  </a>
                </p>
                <p>
                  <a href={Link.LinkedIn}>
                    <img
                      className='settings-menu-img'
                      src={linkedinImg}
                      alt={Text.LinkedIn}
                    />
                  </a>
                </p>
              </div>
            </div>
          : null
        }
        <div
          className={this.props.settingsIsActive
            ? 'menu-button menu-is-active'
            : 'menu-button menu-is-inactive'
          }
          onClick={this.toggleMenu}
        >
          <img
            className='menu-button-img'
            src={this.props.settingsImgSrc}
            alt={this.props.settingsImgAlt}
          />
        </div>
      </div>
    );
  }
}

export default Settings;
