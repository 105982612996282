import React, { Component } from 'react';
import './../styles/Spin.css';
import propellerhatImg from './../assets/propellerhat-1024x512.png';
import { Text, tasks } from './../util/util';

type State = {
  isSpinning: boolean;
  taskText: string | null;
  taskTexts: string[][];
  taskTextsRemaining: string[];
}

class Spin extends Component<{}, State> {

  /**
   * Used to prevent a memory leak when changing views during the spin animation
   */
  _isMounted = false;

  state: State = {
    isSpinning: false,
    taskText: null,
    taskTexts: tasks,
    taskTextsRemaining: [],
  }

  componentDidMount() {
    this._isMounted = true;
    const taskTextsRemaining = this.getTaskTexts();
    this.setState(() => ({
      taskTextsRemaining: taskTextsRemaining,
    }));
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getRandomTaskText = () => {
    const randomIndex = Math.floor(Math.random() * this.state.taskTextsRemaining.length);
    const randomText = this.state.taskTextsRemaining[randomIndex];
    const taskTextsRemaining = this.state.taskTextsRemaining.length < 2
      ? this.getTaskTexts()
      : this.state.taskTextsRemaining.filter(x => x !== randomText);
    if (this._isMounted) {
      this.setState(() => ({
        taskText: randomText,
        taskTextsRemaining: taskTextsRemaining,
      }));
      setTimeout(() => {
        this.spinEnd();
      }, 400);
    }
  }
  
  getTaskTexts = () => {
    const taskTextsRemaining: string[] = [];
    for (const i in this.state.taskTexts) {
      for (const ii in this.state.taskTexts[i]) {
        taskTextsRemaining.push(this.state.taskTexts[i][ii]);
      }
    }
    return taskTextsRemaining;
  }

  spinEnd = () => {
    const isSpinning = !this.state.isSpinning;
    if (this._isMounted) {
      this.setState(() => ({
        isSpinning: isSpinning
      }));
    }
  }

  spinStart = () => {
    if (!this.state.isSpinning) {
      const isSpinning = !this.state.isSpinning;
      if (this._isMounted) {
        this.setState(() => ({
          isSpinning: isSpinning,
          taskText: null,
        }));
        setTimeout(() => {
          this.getRandomTaskText();
        }, 1600);
      }
    }
  }

  render() {
    return (
      <div
        className='spin'
        onClick={this.spinStart}
      >
        <div className='spin-container-top'></div>
        <div
          className={this.state.isSpinning
            ? 'animation-spin-y spin-container-mid'
            : 'spin-container-mid'
          }
        >
        {this.state.taskText === null
          ? <img
              className='spin-img'
              src={propellerhatImg}
              alt={Text.Propellerhat}
            />
          : <p>{this.state.taskText}</p>
        }
        </div>
        <div className='spin-container-bottom'>
          <p
            className={!this.state.isSpinning && this.state.taskText === null
              ? 'animation-fade-in spin-hint-text'
              : 'spin-hint-text'
            }
          >
            {Text.Hint}
          </p>
        </div>
      </div>
    );
  }
}

export default Spin;
