import React, { Component } from 'react';
import './../styles/Story.css';
import { Text, story } from './../util/util';

class Story extends Component {
  render() {
    return (
      <div className='story'>
        {story.map(i => (i.includes(Text.HTTP)
          ? <a
              key={i}
              href={i}
            >
              {i}
            </a>
          : <p key={i}>{i}</p>
        ))}
      </div>
    );
  }
}

export default Story;
