/**
 * Link assets
 */
export enum Link {
  Facebook = 'https://www.facebook.com/propellerhat/',
  Instagram = 'https://www.instagram.com/propellerhatco/',
  LinkedIn = 'https://www.linkedin.com/company/propellerhat/?viewAsMember=true/',
  Subscribe = 'https://www.needaspin.com/subscribe/index.html',
  Twitter = 'https://www.twitter.com/propellerhatco/',
}

/**
 * Text assets
 */
export enum Text {
  Facebook = 'Facebook',
  Hint = 'Tap to spin',
  HTTP = 'http',
  Instagram = 'Instagram',
  LinkedIn = 'LinkedIn',
  Navigation = 'Navigation',
  Propellerhat = 'Propellerhat',
  Settings = 'Settings',
  Subscribe = 'Subscribe',
  Twitter = 'Twitter',
}

/**
 * Menu items
 */
export const menu = [
  'Navigation',
  'Settings',
];

/**
 * Navigation menu items
 */
export const navigation = [
  'Spin',
  'Story',
];

/**
 * Settings menu items
 */
export const settings = [
  '',
];

/**
 * Story text assets
 */
export const story = [
  'Story of a spin',
  'Propellerhat ® Spin is a practical tool for leaders who need to get things done. That\'s you, my friend. Spin is a simple tool and task manager for the creative mind.',
  'We rarely get to see a perfect waterfall. A new and creative thought is always a delight. In agile and sometimes mysterious ways we can manage and get things done. With our method you can start a new uplifting journey with a positive spin.',
  'Spin is designed to help you find focus when you are lost. It gives you a push with new ideas and projects. It is designed to take you to a different path. To enhance curiosity, deliver value and optimize performance with a positive spin. It was created because we needed it at Propellerhat. And now are making it available to all - in case you also #needaspin',
  'Instructions of safe use',
  'Take a deep breath, relax and open your mind. You have the solution. Tap to spin and take immediate action.  The method is used to propel a new layer of creative thinking to your daily work and life.',
  'Background in science',
  'Propellerhat ® Spin has been created with a scientific framework in mind. It draws creatively from years of action research with academic and entrepreneurial innovation projects.',
  'Limitation of Liability',
  'Propellerhat Management Oy is not responsible for any consequential damages of individual actions that users take in their professional life. You take action on your own risk, as always. We hope it all works out for the best.',
  'Privacy and data protection',
  'https://propellerhat.co/privacy-and-data-protection-policy/ ',
  'Copyright 2020 Propellerhat Management Oy',
  'All rights reserved. Propellerhat ® is a trademark of Propellerhat Management Oy',
  'ver.1.1.2',
];

/**
 * Task text assets
 */
export const tasks = [
  [
    // Systemic perception
    'Take a look around.',
    'Observe the unknown.',
    'See for yourself.',
    'See what is obvious.',
    'Look them straight in the eye.',
    'What have you missed?',
    'Connect the dots ...',
    'Perceive the obsolete.',
    'What is going on here?',
  ],
  [
    // Attunement
    'Tune in.',
    'Turn up the volume.',
    'Can you see what I see?',
    'Make a social call.',
    'You must have missed that connection.',
    'More than words.',
    'I know what you are thinking...',
    'There was a connection afterall!',
    'Be present.',
  ],
  [
    // Reflection
    'Think backwards.',
    'How might we re-engineer this?',
    'Make a choice.',
    'What is ethical in this situation?',
    'How might this go wrong?',
    'Think ahead and then come back to this.',
    'Look in the rearviewmirror.',
    'Consider another option.',
    'Make a decision.',
  ],
  [
    // Positive engagement
    'Say thanks.',
    'I kind of like this idea.',
    'Success.',
    'That was nice.',
    'No is not an answer.',
    'Let\'s give it another try.',
    'Respect and listen.',
    'Give compliments to the team.',
    'We can do this!',
  ],
  [
    // Spirited discovery
    'Wow, just amazing!',
    'Committed for life.',
    'That sounds interesting.',
    'Be passionate!',
    'Take another look.',
    'Could you repeat that?',
    'We are on discovery channel!',
    'Good vibrations!',
    'This rocks!',
  ],
  [
    // Effective responsiveness
    'Do it now.',
    'Act immediately.',
    'It is now or never.',
    'Delivery complete.',
    'This is enough.',
    'Stop wondering around.',
    'Take meaningful action.',
    'Work starts now.',
    'Finish it.',
  ],
  [
    // Wise action
    'Think and act.',
    'On the long term it is going to be just fine.',
    'We have seen this before, haven\'t we?',
    'Let\'s show them how it\'s done.',
    'Do the right thing.',
    'Understand and take a stand.',
    'Be sensible.',
    'Wisdom in action.',
    'What makes sense?',
  ],
  [
    // Positive attitude
    'It is what it is.',
    'Send them love and happiness.',
    'Escalate towards the positive.',
    'Done and dusted.',
    'Take it as a new opening.',
    'Tell them something positive.',
    'Call them with encouragement.',
    'That idea is going to fly!',
    'Be happy.',
  ]
];
